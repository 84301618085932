exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-c-[id]-index-tsx": () => import("./../../../src/pages/c/[id]/index.tsx" /* webpackChunkName: "component---src-pages-c-[id]-index-tsx" */),
  "component---src-pages-c-[id]-manage-tsx": () => import("./../../../src/pages/c/[id]/manage.tsx" /* webpackChunkName: "component---src-pages-c-[id]-manage-tsx" */),
  "component---src-pages-c-[id]-messages-tsx": () => import("./../../../src/pages/c/[id]/messages.tsx" /* webpackChunkName: "component---src-pages-c-[id]-messages-tsx" */),
  "component---src-pages-c-create-tsx": () => import("./../../../src/pages/c/create.tsx" /* webpackChunkName: "component---src-pages-c-create-tsx" */),
  "component---src-pages-c-index-tsx": () => import("./../../../src/pages/c/index.tsx" /* webpackChunkName: "component---src-pages-c-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

