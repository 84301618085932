import './styles/global.css'
import * as React from 'react'
import { Provider } from 'react-redux'
import { store } from './store'
import { Toaster } from 'react-hot-toast'

const Wrapper = ({ element }: any) => {
	return (
		<>
			<html className="h-full bg-gray-100" />
			<body className="h-full" />
			<Toaster />
			<Provider store={store}>
				{element}
			</Provider>
		</>
	)
}

export default Wrapper