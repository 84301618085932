import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import user from './reducers/user.reducer'
import logger from 'redux-logger'
import { isDevelopment } from '../util/env.util'

const makeStore = () =>
	configureStore({
		reducer: {
			user,
		},
		middleware: (getDefaultMiddleware) => [
			...getDefaultMiddleware(),
			...(isDevelopment() ? [logger] : []),
		],

		devTools: true,
	})

export const store = makeStore()

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	AppState,
	unknown,
	Action
>;