import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	token: '',
	id: '',
	name: '',
	phone_number: '',
	member: [],
	admin: [],
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (_, { payload }) => payload,
		clearUser: () => initialState,
	},
})

export const { setUser, clearUser } = userSlice.actions
export default userSlice.reducer

